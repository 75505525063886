.audioPulse {
  display: flex;
  width: 24px;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.5s;

  & > div {
    background-color: var(--Neutral-30);
    border-radius: 1000px;
    width: 4px;
    min-height: 4px;
    border-radius: 1000px;
    transition: height 0.1s;
  }

  &.hover > div {
    animation: hover 1.4s infinite alternate ease-in-out;
  }

  height: 4px;
  transition: opacity 0.333s;

  &.active {
    opacity: 1;

    & > div {
      background-color: var(--Neutral-80);
    }
  }
}

@keyframes hover {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-3.5px);
  }
}

@keyframes pulse {
  from {
    scale: 1 1;
  }

  to {
    scale: 1.2 1.2;
  }
}
