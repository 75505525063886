// Import Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

// Font variables
:root {
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  --font-mono: 'SF Mono', SFMono-Regular, ui-monospace, 'DejaVu Sans Mono', Menlo, Consolas, monospace;
  --text: white;
  --gray-200: #b4b8bb;
  --gray-300: #80868b;
  --gray-500: #5f6368;
  --gray-600: #444444;
  --gray-700: #202020;
  --gray-800: #171717;
  --gray-900: #111111;
  --gray-1000: #0a0a0a;
  --border-stroke: #444444;
  --accent-blue: rgb(161, 228, 242);
  --accent-blue-active-bg: #001233;
  --accent-blue-active: #98beff;
  --accent-blue-headers: #448dff;
  --accent-green: rgb(168, 218, 181);

  --midnight-blue: rgb(0, 18, 51);
  --blue-30: #99beff;

  --accent-red: #ff4600;

  --background: transparent;
  --color: var(--text);

  scrollbar-color: var(--gray-600) var(--gray-900);
  scrollbar-width: thin;

  --font-family: "Space Mono", monospace;

  /* */
  --Neutral-00: #000;
  --Neutral-5: #181a1b;
  --Neutral-10: #1c1f21;
  --Neutral-15: #232729;
  --Neutral-20: #2a2f31;
  --Neutral-30: #404547;
  --Neutral-50: #707577;
  --Neutral-60: #888d8f;
  --Neutral-80: #c3c6c7;
  --Neutral-90: #e1e2e3;

  --Green-500: #0d9c53;
  --Green-700: #025022;

  --Blue-500: #1f94ff;
  --Blue-800: #0f3557;

  --Red-400: #ff9c7a;
  --Red-500: #ff4600;
  --Red-600: #e03c00;
  --Red-700: #bd3000;
}

// Base font styles
body {
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent !important; 
}

code, pre {
  font-family: var(--font-mono);
}

.material-symbols-outlined {
  &.filled {
    font-variation-settings:
      "FILL" 1,
      "wght" 400,
      "GRAD" 0,
      "opsz" 24;
  }
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.h-screen-full {
  height: 100vh;
}

.w-screen-full {
  width: 100vw;
}

.flex-col {
  flex-direction: column;
}

@media (prefers-reduced-motion: no-preference) {}

.streaming-console {
  background: transparent;
  color: var(--gray-300);
  display: flex;
  height: 100vh;
  width: 100vw;

  a,
  a:visited,
  a:active {
    color: var(--gray-300);
  }

  .disabled {
    pointer-events: none;

    >* {
      pointer-events: none;
    }
  }

  main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 1rem;
    max-width: 100%;
    overflow: hidden;
  }

  .main-app-area {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 2rem;
  }

  .function-call {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    overflow-y: auto;
  }
}

/* video player */
.stream {
  flex-grow: 0;
  max-width: 90%;
  border-radius: 32px;
  max-height: fit-content;
  margin-bottom: 2rem;
  position: relative;

  &.aspect-ratio-16-9 {
    aspect-ratio: 16/9;
    width: 90%;
    height: auto;
    object-fit: cover;
  }

  &.hidden {
    display: none;
  }
}

.App.widget-mode {
  .streaming-console {
    padding: 0;
    background: transparent !important;
  }

  .widget-controls {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    border-radius: 8px;
    padding: 8px;
    
    .stream-button {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--Neutral-15);
      border: 1px solid var(--border-stroke);
      cursor: pointer;
      margin-right: 8px;

      &:hover {
        background: var(--Neutral-20);
      }
    }

    .actions-nav {
      display: flex;
      align-items: center;
    }
  }
}

.transparent-mode {
  background: transparent !important;
}

.transparent-bg {
  background: transparent !important;
}

.black-bg {
  background: black !important;
}

.control-tray {
  @media (max-width: 480px) {
    max-width: 100vw;
    overflow: hidden;
  }
}

.widget-mode-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  
  .control-tray {
    margin-left: 40px;
    
    &:only-child {
      margin-left: 0;
    }

    .stream-button {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--Neutral-15);
      border: 1px solid var(--border-stroke);
      cursor: pointer;

      &:hover {
        background: var(--Neutral-20);
      }
    }
  }
}

.widget-stream {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;

 
}
